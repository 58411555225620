<template>
    <div>
        <form-gallery :images="editedVacancy.images"
                      :images-loading="imageUploadForm.busy"
                      :image-deleting-uuid="imageDeletingUuid"
                      @upload-images="uploadImages"
                      @delete-image="deleteImage">
            <queued-files-progress-bar
                class="mb-3"
                :queue="progress.queue"
                :total="progress.total"
                :loaded="progress.loaded"
                :failed="progress.failed"
                :current_file_name="progress.current_file_name"
                @forget-errors="progress.failed = []" />
        </form-gallery>
    </div>

</template>

<script>
    import formHelperMixin from "@/mixins/formHelperMixin";
    import {mapMutations, mapState} from "vuex";
    import FormGallery from "./common/FormGallery";
    import {
        ADD_EDITED_VACANCY_IMAGE,
        DELETE_EDITED_VACANCY_IMAGE_BY_UUID
    } from "@/store/modules/artisticVacancies/mutation-types";
    import QueuedFilesProgressBar from "@/components/Forms/parts/common/QueuedFilesProgressBar";
    import {serialize} from "object-to-formdata";
    import Form from "vform";

    export default {
        components: {QueuedFilesProgressBar, FormGallery},
        data: function () {
            return {
                imageDeletingUuid: null,
                imageUploadForm: new Form({image: null}),
                progress: {
                    queue: [],
                    failed: [],
                    current_file_name: null,
                    total: 0,
                    loaded: 0,
                },
            };
        },
        methods: {
            ...mapMutations('artisticVacancies', [
                ADD_EDITED_VACANCY_IMAGE,
                DELETE_EDITED_VACANCY_IMAGE_BY_UUID,
            ]),
            deleteImage(payload){

                this.imageDeletingUuid = payload.image.uuid;
                this.$_p_formDelete('vacancies/' + this.editedVacancy.id + '/images/' + payload.image.uuid)
                    .then(res => {
                        if (200 === res.status){
                            this[DELETE_EDITED_VACANCY_IMAGE_BY_UUID](payload.image.uuid);
                        }
                    }).then(() => {
                        this.imageDeletingUuid = null;
                });
            },
            async uploadImages(images){

                if (images && images.length){
                    this.progress.queue = images;
                    this.progress.failed = [];

                    while (this.progress.queue.length){

                        let image = this.progress.queue.shift();
                        this.progress.current_file_name = image.name;
                        this.progress.total = null;
                        this.progress.loaded = null;
                        this.imageUploadForm.image = image;
                        try {
                            let response = await this.imageUploadForm.submit('post', `/vacancies/${this.editedVacancy.id}/images`, {
                                // Transform form data to FormData
                                transformRequest: [function (data) {
                                    return serialize(data);
                                }],
                                onUploadProgress: e => {
                                    this.progress.total = e.total;
                                    this.progress.loaded = e.loaded;
                                }
                            });
                            this[ADD_EDITED_VACANCY_IMAGE](response.data);

                        } catch (e) {
                            let message = this.imageUploadForm.errors.has('image')
                                ? this.imageUploadForm.errors.get('image')
                                : e.message;

                            this.progress.failed.push({fileName: this.progress.current_file_name, message: message});
                        }

                        this.imageUploadForm.reset();

                    }

                    this.$buefy.toast.open(this.$t('Images uploaded'));

                    this.progress.current_file_name = null;
                    this.progress.queue = [];
                }
            }
        },
        computed: {
            ...mapState({
                editedVacancy: store => store.artisticVacancies.editedVacancy,
            }),
        },
        mixins: [formHelperMixin],
        name: "ArtisticVacancyFormGallery"
    };
</script>

<style scoped>
</style>