<template>
    <form class="form" v-if="vacancy && artisticProfileTypes">

        <!-- BACK BUTTON -->

        <b-button @click="$router.go(-1)" size="is-small" class="mb-3" icon-left="chevron-left">
            {{$t('back')}}
        </b-button>

        <!-- ARTISTIC PROFILE TYPE -->

        <b-field :label="$t('Who are you looking for?')"
                :message="$_p_formGetError('artistic_profile_type_id')"
                :type="{'is-danger': $_p_formHasError('artistic_profile_type_id')}">
            <b-select v-model="vacancy.artistic_profile_type_id"
                      :disabled="vacancy.published"
                      @input="$_p_formClearError('artistic_profile_type_id')"
                      :placeholder="$t('Select')">
                <option v-for="type in artisticProfileTypes"
                        :key="'artistic-profile-type-' + type.id"
                        :value="type.id"
                        v-text="$t(type.name)">
                </option>
            </b-select>
        </b-field>

        <!--SHOW STYLISTIC OPTIONS aka SHOW TYPES-->

        <b-field v-if="modelCanHasShowStylisticOptions" :label="$t('Show type')">
            <b-select v-model="stylisticOptions.show" :placeholder="$t('Select show type')">
                <option v-for="showType in showStylisticOptions"
                        :key="'show-stylistic-option-' + showType.id"
                        :value="[showType.id]"
                        v-text="$t(showType.name)">
                </option>
            </b-select>
        </b-field>

        <!-- CITY -->

        <cities-list @selected="city => {vacancy.city_id = (null === city) ? null : city.id}"
                     :title="$t('Select city')"
                     :preselected-city-id="editedVacancy.city_id"
                     :errors="errors"
                     @clear-error="$_p_formClearError('city_id')"
                     :error-message="$_p_formGetError('city_id')">
        </cities-list>

        <!-- GLOBAL SEARCH -->

        <b-field >
            <b-switch v-model="vacancy.global_search">
                {{$t('search all over Ukraine')}}
            </b-switch>
        </b-field>

        <!--ABROAD-->

        <b-field>
            <b-switch v-model="vacancy.abroad_contract">{{$t('is abroad contract')}}</b-switch>
        </b-field>

        <!-- LOCATION-->

        <b-field :label="$t('Location')" :message="$t('indicate a place of work')">
            <b-input v-model="vacancy.location" > </b-input>
        </b-field>

        <!-- DATE -->

        <b-field :label="$t('Date')">
            <b-datepicker
                    v-model="date"
                    @input="setVacancyDate"
                    :placeholder="$t('Click to select') + '...'"
                    :date-formatter="formatDateString"
                    icon="calendar-week">

                <b-button type="is-danger"
                          size="is-small"
                          @click="date = vacancy.date = null">
                    {{$t('clear')}}
                </b-button>

            </b-datepicker>
        </b-field>


        <!-- TIME -->
        <!--<div class="columns">
            <div class="column">
                <b-field label="Указать время?">
                    <b-switch v-model="setTime">
                        {{setTime ? 'Да' : 'Нет'}}
                    </b-switch>
                </b-field>
            </div>
            <div class="column">
                <b-field label="Укажите время" v-if="setTime">
                    <b-timepicker
                            inline
                            :time-formatter="formatTimeString"
                            v-model="vacancy.time"
                            placeholder="Нажмите для выбора..."
                            icon="clock"
                            hour-format="24">
                    </b-timepicker>
                </b-field>

            </div>
        </div>-->

        <!-- INSTRUMENTS-->

        <div class="field" v-if="modelCanHasInstruments">
            <label class="label">{{$t('Select instruments')}}</label>
            <div class="control">
                <div class="select is-multiple">
                    <select  v-model="vacancy.instruments" multiple>
                        <option v-for="instrument in instruments"
                                :key="'instrument-' + instrument.id"
                                :value="instrument.id"
                                v-text="$t(instrument.name)">
                        </option>
                    </select>
                    <p class="help">{{$t('Select instruments you need')}}</p>
                </div>
            </div>
        </div>

        <!-- MUSIC STYLISTIC OPTIONS-->


        <div class="field" v-if="modelCanHasMusicStylisticOptions">
            <label class="label">{{$t('Select musical styles')}}</label>
            <div class="control">
                <div class="select is-multiple">
                    <select multiple v-model="stylisticOptions.musical">
                        <option v-for="musicalOption in musicalStylisticOptions"
                                :value="musicalOption.id"
                                :key="'musical-stylistic-option-' + musicalOption.id"
                                v-text="$t(musicalOption.name)">
                        </option>
                    </select>
                    <p class="help">{{$t('select musical styles you need')}}</p>
                </div>
            </div>
        </div>

        <!-- DANCING STYLISTIC OPTIONS-->

        <div class="field" v-if="modelCanHasDancingStylisticOptions">
            <label class="label">{{$t('Select dancing styles')}}</label>
            <div class="control">
                <div class="select is-multiple">
                    <select  v-model="stylisticOptions.dancing" multiple>
                        <option v-for="dancingOption in dancingStylisticOptions"
                                :key="'dancing-stylistic-option-' + dancingOption.id"
                                :value="dancingOption.id"
                                v-text="$t(dancingOption.name)">
                        </option>
                    </select>
                    <p class="help">{{$t('select dancing styles you need')}}</p>

                </div>
            </div>
        </div>

        <!-- FEATURES -->

        <b-field :label="$t('Select features')" v-if="modelCanHasFeatures">

            <b-checkbox v-model="vacancy.features"
                        v-for="feature in artisticProfileFeatures"
                        :disabled="!!(vacancy.features.length && !vacancy.features.includes(feature.id))"
                        :key="`feature-${feature.id}`"
                        :native-value="feature.id">
                {{ $t(feature.type)}}
            </b-checkbox>

        </b-field>

        <!-- REQUIREMENTS -->

        <b-field :label="$t('Requirements')"
                 :message="$_p_formHasError('requirements') ? $_p_formGetError('requirements') : $t('describe the requirements in details')"
                :type="{'is-danger' : $_p_formHasError('requirements')}">

            <b-input type="textarea"
                     @input="$_p_formClearError('requirements')"
                     maxlength="1000"
                     v-model="vacancy.requirements">
            </b-input>
        </b-field>

        <!-- IMAGES GALLERY -->
        <artistic-vacancy-form-gallery />

        <!-- LINKS -->

        <additional-links :editable-model="vacancy"
                          @add-link="addVacancyLink"
                          @delete-link="deleteVacancyLink"
                          :field-label="$t('Example links')" />

        <!-- FEE OPTIONS-->

        <div class="field">
            <label class="label">{{$t('Fee')}}</label>

            <div class="control">
                <label class="radio">
                    <b-radio native-value="fixed" @input="vacancy.fee_amount_by_request = false" v-model="vacancy.fee_type">{{$t('fixed')}}</b-radio>

                </label>
                <label class="radio">
                    <b-radio native-value="agreed" v-model="vacancy.fee_type">{{$t('agreed')}}</b-radio>
                </label>
            </div>
        </div>


        <div v-if="'fixed' === vacancy.fee_type" :key="'fixed_fee'" class="field">
            <b-field :type="{'is-danger': $_p_formHasError('fee_amount')}">
                <b-select v-model="vacancy.fee_currency">
                    <option>UAH</option>
                    <option>USD</option>
                </b-select>

                <b-input @input="$_p_formClearError('fee_amount')"
                         v-model="vacancy.fee_amount"
                         type="number" min="1"
                         :placeholder="$t('amount')"></b-input>
            </b-field>
            <p class="help is-danger" v-if="$_p_formHasError('fee_amount')">{{$_p_formGetError('fee_amount').join(', ')}}</p>
        </div>


        <div v-else :key="'agreed_fee'" class="field">
            <b-field>
                <b-switch v-model="vacancy.fee_amount_by_request">
                    {{$t('Request a proposal for a fee from a candidate')}}
                </b-switch>
            </b-field>
        </div>

        <!-- SUBMIT,CANCEL BUTTONS-->

        <b-field>
            <div class="buttons">
                <b-button @click="$router.go(-1)" type="is-secondary" size="is-medium" v-text="$t('Cancel')"></b-button>
                <b-button @click="handleFormSubmit" :loading="formBusy" type="is-success" size="is-medium">
                    {{ vacancy.published ?  $t('Update') : $t('Publish') }}
                </b-button>
            </div>
        </b-field>

    </form>

</template>

<script>
    import {mapActions, mapMutations, mapState} from "vuex";
    import modelRelationsHelperMixin from "@/mixins/modelRelationsHelperMixin";
    import AdditionalLinks from "./parts/common/AdditionalLinks";
    // import ArtisticVacancyFormGallery from "./parts/ArtisticVacancyFormGallery";
    import formHelperMixin from "@/mixins/formHelperMixin";
    import CitiesList from "./parts/common/CitiesList";
    import {SET_EDITED_ARTISTIC_VACANCY} from "@/store/modules/artisticVacancies/mutation-types";
    import {GET_DATA_FOR_CREATION} from "@/store/modules/base/action-types";
    import {
        DESTROY_ARTISTIC_VACANCY,
        UPDATE_ARTISTIC_VACANCY
    } from "@/store/modules/artisticVacancies/actions-types";
    import ArtisticVacancyFormGallery from "./parts/ArtisticVacancyFormGallery";

    export default {
        name: "ArtisticVacancyForm",
        components: {ArtisticVacancyFormGallery, CitiesList, AdditionalLinks},
        data: function(){
            return {
                vacancy: null,
                date: null,
                setTime: false,
                feeType: 'fixed',
                exampleLink: '',
                imagesList: [],
                stylisticOptions: {
                    musical: [],
                    dancing: [],
                    show: null,
                },
            };
        },
        methods: {
            ...mapMutations([
              /*  'addVacancyLink',
                'deleteVacancyLink'*/
            ]),
            ...mapActions('base', [
                GET_DATA_FOR_CREATION,
            ]),
            ...mapActions('artisticVacancies', [
                UPDATE_ARTISTIC_VACANCY,
                DESTROY_ARTISTIC_VACANCY,
            ]),
            ...mapMutations('artisticVacancies', [
                SET_EDITED_ARTISTIC_VACANCY,
            ]),
            handleFormSubmit(){

                this.$_p_formDispatch(UPDATE_ARTISTIC_VACANCY, this.prepareVacancyData())
                    .then(() => {
                        this.$buefy.toast.open({
                            message: this.$t('Changes applied'),
                            type: 'is-success',
                        });
                        this.$buefy.dialog.confirm({
                            title: this.$t('Vacancy has been updated'),
                            message: this.$t('Go to applications list?'),
                            confirmText: this.$t('Yes'),
                            cancelText: this.$t('No'),
                            onConfirm: () => this.$router.push({name: 'organizerDashboard'}),
                        });
                    })
                    .catch(error => {
                        if (422 === error.response.status){
                            this.$buefy.toast.open({
                                message: this.$t('Please, check form fields'),
                                type: 'is-danger'
                            });
                        }
                    });

            },
            formatDateString(date){
                return date.toISOString().substring(0, 10);
            },
            setVacancyDate(){
                 if (this.date){
                    this.vacancy.date = this.formatDateString(new Date(this.date));
                }
            },
            addVacancyLink(link){
                this.vacancy.links.push(link);
            },
            deleteVacancyLink(payload){
                this.vacancy.links.splice(payload.index,1);
            },
            prepareVacancyData(){

                let formData ={...this.vacancy};

                /*Generate title*/
                formData.title = this.compileVacancyTitle();

                /* merge stylisticOptions ids to one array */

                formData.stylistic_options = this.stylisticOptions.dancing.concat(this.stylisticOptions.musical);

                /* merge required stylistic options to one array */
                formData.required_stylistic_options = (null === this.stylisticOptions.show) ? [] : this.stylisticOptions.show;

                /*publish status true*/
                formData.published = true;

                return formData;
            },
            compileVacancyTitle(){
                let title = 'Вакансия ';

                if (this.vacancy.artistic_profile_type_id){
                    title += 'для ' + this.$t(this.artisticProfileTypes.find(type => type.id === this.vacancy.artistic_profile_type_id).name);
                }

                if (this.vacancy.fee_amount){
                    title += ", с бюджетом ";
                    title += this.vacancy.fee_currency === 'USD' ? '$' : '';
                    title += this.vacancy.fee_amount;
                    title += this.vacancy.fee_currency === 'UAH' ? ' грн.' :'';
                } else {
                    title += ', с договорным бюджетом';
                }

                if (this.vacancy.city_id){
                    title += ', для артистов в г.';
                    title += this.cities.find(city => city.id === this.vacancy.city_id).name;
                }

                if(this.vacancy.abroad_contract){
                    title += ' и выездом заграницу';
                }

                return title;
            },
            loadEditableVacancy(){
                // this.vacancy = {...this.editedVacancy};
                this.vacancy = this._.cloneDeep(this.editedVacancy);

                if (null === this.editedVacancy){
                    return;
                }

                /*Pluck instruments id*/
                ['instruments'].forEach(relationName => {
                    if(this.vacancy[relationName].length){
                        this.vacancy[relationName] = this.vacancy[relationName].map(function(relation){

                            return ('object' === typeof relation && undefined !== relation.id) ? relation.id : relation;

                        });
                    } else{
                        this.vacancy[relationName] = [];
                    }
                });

                //Pluck ids for features
                if (this.vacancy.features && this.vacancy.features.length){
                    this.$set(this.vacancy, 'features', this.vacancy.features.map(f => f.id));
                }

                /*Sort stylistic options by type to component local data and pluck ids*/
                this.stylisticOptionTypes.forEach(type => {
                    let attachedVacancyStylisticOptions = this.vacancy.stylistic_options.filter(option => {
                        return option.type_id === type.id;
                    }).map(option => {
                        return ('object' === typeof option && undefined !== option.id) ? option.id : option;
                    });
                    /*save attached vacancy stylistic options to local variable or keep default local values*/
                    if (attachedVacancyStylisticOptions.length){
                        this.stylisticOptions[type.name] = attachedVacancyStylisticOptions;
                    }
                });

                if (this.vacancy.date){
                    this.date = new Date(Date.parse(this.vacancy.date));
                    this.setVacancyDate();
                } else {
                    this.date = null;
                }

                this.globalSearch = !this.vacancy.city_id;
                this.setTime = !!this.vacancy.time;
            },
        },
        computed: {
            ...mapState({
                editedVacancy: state => state.artisticVacancies.editedVacancy,
                artisticProfileTypes: state => state.artisticProfileTypes.types,
                cities: state => state.cities.cities,
                instruments: state => state.instruments.instruments,
                stylisticOptionTypes: state => state.stylisticOptionTypes.types,
                artisticProfileFeatures: state => state.artisticProfileFeatures.features
            }),
            title(){
                return this.compileVacancyTitle();
            },
            contactsPlaceholder: function () {
                return this.form.useAccountContacts ? 'Будут использованы контакты User\'а' : 'Контактные данные для этого профиля';
            },
            musicalStylisticOptions(){
                return this.stylisticOptionTypes.find(type => {
                    return 'musical' === type.name;
                }).stylistic_options;
            },
            dancingStylisticOptions(){
                return this.stylisticOptionTypes.find(type => {
                    return 'dancing' === type.name;
                }).stylistic_options;
            },
            showStylisticOptions(){
                return this.stylisticOptionTypes.find(type => {
                    return 'show' === type.name;
                }).stylistic_options;
            },
        },
        mixins: [modelRelationsHelperMixin, formHelperMixin],
        async created() {
            this.loadEditableVacancy();
            this.$Progress.finish();
        },
        watch: {
            editedVacancy(){
                this.loadEditableVacancy();
            },
        },
    };
</script>

<style scoped>

</style>