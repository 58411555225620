<template>
    <artistic-vacancy-form v-if="editedVacancy" />
</template>

<script>
    import ArtisticVacancyForm from "@/components/Forms/ArtisticVacancyForm";
    import {mapActions, mapMutations, mapState} from "vuex";
    import {DESTROY_ARTISTIC_VACANCY, GET_ARTISTIC_VACANCY} from "../../store/modules/artisticVacancies/actions-types";
    import store from '@/store/index';
    import {GET_DATA_FOR_CREATION} from "../../store/modules/base/action-types";
    import {DELETE_ARTISTIC_VACANCY_FROM_LIST, SET_EDITED_ARTISTIC_VACANCY} from "../../store/modules/artisticVacancies/mutation-types";
    export default {
        name: "EditOrganizerVacancy",
        title(){ return this.$t('Edit vacancy');},
        computed: {
            ...mapState('artisticVacancies', [
                'editedVacancy',
            ]),
        },
        methods: {
            ...mapActions('artisticVacancies', [
                GET_ARTISTIC_VACANCY,
                DESTROY_ARTISTIC_VACANCY,
            ]),
            ...mapMutations('artisticVacancies', [
                SET_EDITED_ARTISTIC_VACANCY,
                DELETE_ARTISTIC_VACANCY_FROM_LIST
            ]),
            ...mapActions('base', [
                GET_DATA_FOR_CREATION,
            ]),
        },
        async created(){
            await this[GET_DATA_FOR_CREATION]();
            if (!this.$route.params.vacancy){
                store.dispatch('artisticVacancies/' + GET_ARTISTIC_VACANCY, this.$route.params.id)
                    .then(res => {
                        store.commit('artisticVacancies/' + SET_EDITED_ARTISTIC_VACANCY, res.data);
                    });
            } else {
                store.commit('artisticVacancies/' + SET_EDITED_ARTISTIC_VACANCY, this.$route.params.vacancy);
            }
        },
        beforeRouteLeave(to, from, next){
            if (this.editedVacancy && !this.editedVacancy.published){
                this.$buefy.dialog.confirm({
                    message: this.$t('The vacancy was not published') + '. ' + this.$t('Remove unpublished vacancy?'),
                    confirmText: this.$t('Yes'),
                    cancelText: this.$t('No'),
                    onConfirm: () => {
                        this[DESTROY_ARTISTIC_VACANCY](this.editedVacancy.id)
                            .then(() => {
                                this[DELETE_ARTISTIC_VACANCY_FROM_LIST](this.editedVacancy.id);
                                this[SET_EDITED_ARTISTIC_VACANCY](null);
                                next();
                            });
                    },
                    onCancel: () => {
                        this[SET_EDITED_ARTISTIC_VACANCY](null);
                        next();
                    }
                });
            } else {
                this[SET_EDITED_ARTISTIC_VACANCY](null);
                next();
            }
        },
        components: {ArtisticVacancyForm}
    };
</script>

<style scoped>

</style>